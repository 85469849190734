import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { eventCallbackTriggers } from "../../../templates/wppage";
import "./donateBox.css";

const TreeDonationBox = ({ config, defaultTrees, perTreeCost, festival }) => {
  const [amount, setAmount] = useState(200);
  const [err, setError] = useState("");
  const [value, setValue] = useState(config?.initialAmount);
  const [lastButton, setLastButton] = useState(null);
  const [flag, setFlag] = useState(true);

  const increment = () => setValue((prevValue) => Number(prevValue) + 1);

  const decrement = () =>
    setValue((prevValue) => (prevValue > 1 ? Number(prevValue) - 1 : 1));

  const handlePresetClick = (amt) => {
    if (lastButton === amt) {
      setValue((prevValue) => Number(prevValue) + amt);
    } else {
      setValue(amt);
    }
    setLastButton(amt);
  };

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("dollar-a-day-usa", event),
    []
  );
  const pageContent = () => {
    return (
      `<gatsby_donation amount="${amount}" donationid="${config?.donationId}" btntext="Proceed to Donate"` +
      'projectid="" btnbg="#10AE62" colortext="black" colorprimary="#10AE62" classname="donateBtnStyles"' +
      'colorsecondary="#10AE62" colorbackground="white" colormuted="#f6f6f6"' +
      `colorhighlight="#efeffe" titletext="${config?.titletext}" isrecurring="false" removeAccountType="false" />`
    );
  };
  const PageContent = React.memo(PageContentNonMemoized);

  const handleKeyDown = (e) => {
    // Allow only digits, Backspace, and Delete keys
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (value < 1) {
      setError("Please select a min of 1 tree for donation");
      setFlag(false);
      setAmount(0);
    } else {
      setAmount(value * perTreeCost);
      setFlag(true);
      setError("");
    }
  }, [perTreeCost, value]);

  const handleInputChange = (e) => {
    setValue(Number(e.target.value));
  };

  return (
    <div className="donation-box-wrapper">
      <div className="donation-heading-wrapper">
        <h4 className="text-center donation-box-heading">
          <b style={{ color: "#1f4b72" }}>
            Donate a <span style={{ color: "rgb(88 153 208)" }}>Tree</span> for{" "}
            {festival}{" "}
          </b>
        </h4>
      </div>
      <div
        className=" mt-2"
        style={{
          color: "#1f4b72",
          fontFamily: "Poppins",
          fontWeight: "600",
          fontSyle: "normal",
          fontSize: "15px",
        }}
      >
        <p
          style={{
            textAlign: "left",
          }}
          className="paragraph"
        >
          <span
            style={{
              color: "#1f4b72",
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: "15px",
            }}
          >
            Number of Trees to Plant
          </span>
          <span
            className="currency"
            style={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "0",
              fontSize: "14px",
              float: "right",
            }}
          >
            {config?.symbol} {config?.currency}
          </span>
        </p>
      </div>
      <div>
        {/* <input
          type="number"
          inputMode="numeric"
          value={customAmount}
          placeholder={`${config?.symbol}Custom Amount`}
          className="my-2 custom-input"
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === "+") {
              event.preventDefault();
            }
          }}
          onKeyPress={(event) => {
            const invalidChars = ["-", "+", "e", "E"];
            if (invalidChars.includes(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(event) => {
            const pattern = /^[1-9]\d{0,9}$/;
            if (!pattern.test(event?.target?.value)) {
              if (!event?.target?.value || event?.target?.value.length > 10) {
                setAmount(config?.initialAmount);
                setError("Amount must be less than or equal to 1000000000");
                setCustomAmount("");
              } else {
                setAmount(config?.initialAmount);
                setError("Please enter a valid amount");
              }
            } else if (
              event?.target?.value &&
              event?.target?.value.length === 1 &&
              config?.currency === "INR"
            ) {
              setCustomAmount(event?.target?.value);
              setAmount(config?.initialAmount);
              setError("Amount must be more than ₹10");
            } else {
              setAmount("");
              setError("");
              setCustomAmount(event?.target?.value);
            }
          }}
        /> */}
        <div className="counter-container d-flex justify-content-center align-items-center my-3">
          <button
            type="button"
            className="round-button"
            onClick={decrement}
            disabled={value <= 1}
          >
            -
          </button>
          <input
            type="number"
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            className="input"
            min={5}
            max={10000}
          />
          <button
            type="button"
            className="round-button"
            onClick={increment}
            disabled={value >= 10000}
          >
            +
          </button>
        </div>

        <div className="preset-container d-flex justify-content-center align-items-center flex-wrap my-3">
          {defaultTrees?.map((amt) => (
            <button
              type="button"
              key={amt}
              onClick={() => handlePresetClick(amt)}
              className={`preset-button button-type-${amt} ${
                lastButton === amt ? "preset-button-active" : ""
              }`}
            >
              {amt}
            </button>
          ))}
        </div>
        {err && (
          <p
            className="paragraph"
            style={{ color: "red", fontSize: "14px", textAlign: "center" }}
          >
            {err}
          </p>
        )}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{
          color: "#1f4b72",
          fontFamily: "Poppins",
          fontWeight: "600",
          fontSyle: "normal",
        }}
      >
        <div>Donation Amount</div>
        <div>
          <b>
            {config?.symbol}
            {amount?.toFixed(2)}
          </b>
        </div>
      </div>
      <div className={`${flag ? "" : "disabled-div"}`}>
        <PageContent
          eventCallback={eventCallback}
          pageContent={pageContent()}
        />
      </div>
    </div>
  );
};
TreeDonationBox.propTypes = {
  config: PropTypes.shape({
    initialAmount: PropTypes.number,
    donationId: PropTypes.number,
    symbol: PropTypes.string,
    currency: PropTypes.string,
    titletext: PropTypes.string,
  }).isRequired,
  perTreeCost: PropTypes.number.isRequired,
  defaultTrees: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  festival: PropTypes.string.isRequired,
};

export default TreeDonationBox;
